import React from 'react';
import ComponentLnd30 from '../../components/lnd30';

// import img from "../../img/lnd30";

export default () => {
    return (
       <ComponentLnd30/>
    );
};
