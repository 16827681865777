import React, {useEffect} from "react";

import styles from '../styles.module.scss';

export default ({
                    userEmail,
                    handleInputUserEmail,
                    userPassword,
                    handleInputUserPassword,
                    warningText,
                    setWarningText,
                    onSubmit
                }) => {
    useEffect(() => {
        return () => setWarningText('');
    }, []);//eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div className={`${styles.page_block_2} ${styles.block}`}>
            <div className={styles.page_quest_title}>Registration 3/3</div>
            <div className={styles.page_reg_text}>Please enter your email and password</div>
            <div className={styles.page_reg_input_wrap}>
                <input
                    className={`${styles.page_reg_input} ${!!warningText?.length ? styles.error : ''}`}
                    type="email"
                    placeholder="Enter your email here..."
                    value={userEmail}
                    onChange={(e) => handleInputUserEmail(e.currentTarget.value)}
                />
                <input
                    className={`${styles.page_reg_input} ${!!warningText?.length ? styles.error : ''}`}
                    type="text"
                    placeholder="Enter your password here..."
                    value={userPassword}
                    onChange={(e) => handleInputUserPassword(e.currentTarget.value)}
                />
                {!!warningText?.length && <span>{warningText}</span>}
            </div>
            <div className={`${styles.page_find_btn} ${styles.btn} ${styles.fill}`} onClick={onSubmit}>FIND YOUR MATCHES</div>
        </div>
    )
}