export const LND30 = 'lnd30';

// TRAFFIC ROUTE
export const LND30T = 'lnd30t';

export const ROUTE_LIST_TRAFFIC = [LND30T];

export const ROUTE_LIST = [
    LND30,
];

export const ROUTE_SOURCE_MAP = {
    [LND30]: [LND30, LND30T],
};