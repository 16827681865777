import React from "react";
import Buttons from "../Buttons";

import styles from '../styles.module.scss';

export default ({changeBlock}) => {
    return(
        <div className={`${styles.page_block_2} ${styles.block}`}>
            <div className={styles.page_quest_title}>Question 2/4</div>
            <div className={styles.page_quest_text}>Are you okay with the woman making the first move?</div>
            <Buttons changeBlock={changeBlock} btnName1="YES" btnName2="SKIP"/>
        </div>
    )
}