import React from "react";

import styles from '../styles.module.scss';

export default ({changeBlock}) => {
    return(
        <div className={`${styles.page_block_1} ${styles.block}`}>
            <div className={styles.page_big_title}>True Love is Few Clicks Away!</div>
            <div className={styles.page_start_text}>Quick wit and beauty of our members will leave you speechless. Chat with single women now!</div>
            <div className={styles.page_start_text}>Dating for men over 45!</div>
            <div className={`${styles.page_start_btn} ${styles.btn} ${styles.fill}`} onClick={changeBlock}>OK</div>
            <div className={styles.page_start_reg_text}>
                <span>Free registration!</span><br/>
                no credit card required
            </div>
        </div>
    )
}