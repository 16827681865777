import React from 'react';
import styles from "../styles.module.scss";
import img from "../../../img/lnd30";

export default () => {
    return (
        <header className={styles.page_header}>
            <img src={img.images['logo.svg']} alt=""/>
        </header>
    )
}