import React from "react";
import Buttons from "../Buttons";

import styles from '../styles.module.scss';

export default ({changeBlock}) => {
    return(
        <div className={`${styles.page_block_2} ${styles.block}`}>
            <div className={styles.page_quest_title}>Question 3/4</div>
            <div className={`${styles.page_quest_text} ${styles.text_quest_4}`}>What kind of body type do you prefer?</div>
            <div className={styles.page_quest_checks_wrap}>
                <div className={styles.page_quest_checks_row}>
                    <input id="q3_i1" type="radio" name="q3_i"/>
                    <label htmlFor="q3_i1">Skinny</label>
                </div>
                <div className={styles.page_quest_checks_row}>
                    <input id="q3_i2" type="radio" name="q3_i"/>
                    <label htmlFor="q3_i2">Average</label>
                </div>
                <div className={styles.page_quest_checks_row}>
                    <input id="q3_i3" type="radio" name="q3_i"/>
                    <label htmlFor="q3_i3">BBW (Big Beautiful Woman)</label>
                </div>
                <div className={styles.page_quest_checks_row}>
                    <input id="q3_i4" type="radio" name="q3_i"/>
                    <label htmlFor="q3_i4">No Preference</label>
                </div>
            </div>
            <Buttons changeBlock={changeBlock} btnName1="NEXT" btnName2="SKIP"/>
        </div>
    )
}