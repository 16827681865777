import React, {useEffect, useState} from 'react';
import '../../styles/lnd30/main.css';
import styles from './styles.module.scss';
import '../../styles/lnd30/styles.scss';
import img from "../../img/lnd30";

import Header from './Header';
import Block1 from './Block1';
import Block2 from './Block2';
import Block3 from './Block3';
import Block4 from './Block4';
import Block5 from './Block5';
import Block6 from './Block6';
import Block7 from './Block7';
import Block8 from './Block8';
import TermsModal from '../../components/common/Modals/TermsModal';
import LoaderModal from '../../components/common/Modals/LoaderModal';

import useCheckValidToken from '../../hooks/useCheckValidToken';
import useInitLndBlock from '../../hooks/lnd30/useInitLndBlock';
import useInitTerm from '../../hooks/useInitTerm';
import useGetTrafficConfig from "../../hooks/traffic/useGetTrafficConfig";
import * as DATE from "../../constants/date";

export default () => {
    useCheckValidToken();
    const getTrafficConfig = useGetTrafficConfig();

    const {
        changeBlock,
        formData,
        handleInputUserAge,
        setUserAge,
        warningText,
        setWarningText,
        setUserName,
        handleInputUserName,
        handleInputUserEmail,
        handleInputUserPassword,
        onSubmit,
        modalIsActiveLoader
    } = useInitLndBlock(getTrafficConfig?.trafficConfig);

    const initTerm = useInitTerm();

    const [timerId, setTimerId] = useState(0);
    const [cookiesBannerActive, setCookiesBannerActive] = useState(true);

    const activeBlock = () => {
        const location = window?.location?.href;

        if (location?.includes('block1')) return <Block1 changeBlock={() => changeBlock(2)}/>;
        else if (location?.includes('block2')) return <Block2 changeBlock={() => changeBlock(3)}/>;
        else if (location?.includes('block3')) return <Block3 changeBlock={() => changeBlock(4)}/>;
        else if (location?.includes('block4')) return <Block4 changeBlock={() => changeBlock(5)}/>;
        else if (location?.includes('block5')) return <Block5 changeBlock={() => changeBlock(6)}/>;
        else if (location?.includes('block6')) return <Block6
            userAge={formData?.age}
            handleInputUserAge={handleInputUserAge}
            setUserAge={setUserAge}
            warningText={warningText}
        />;
        else if (location?.includes('block7')) return <Block7
            userName={formData?.name}
            handleInputUserName={handleInputUserName}
            warningText={warningText}
            setWarningText={setWarningText}
            setUserName={setUserName}
        />;
        else if (location?.includes('block8')) return <Block8
            userEmail={formData?.email}
            handleInputUserEmail={handleInputUserEmail}
            userPassword={formData?.password}
            handleInputUserPassword={handleInputUserPassword}
            warningText={warningText}
            setWarningText={setWarningText}
            onSubmit={onSubmit}
        />;
    };

    useEffect(() => {
        const bgList = document.querySelectorAll(".page_bg_item");
        let activeBg = 0;
        clearInterval(timerId);

        const timer = setInterval(() => {
            if (activeBg === bgList.length) {
                activeBg = 0;
            }
            bgList[activeBg].classList.remove("active");
            if (activeBg === bgList.length - 1) {
                bgList[0].classList.add("active");
            } else {
                bgList[activeBg + 1].classList.add("active");
            }
            activeBg += 1;
        }, 3000)

        setTimerId(timer);
    }, []);//eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className={styles.page_bg_wrap}>
                <div className={`${styles.page_bg_item} ${styles.bg1} ${styles.active} page_bg_item active`}/>
                <div className={`${styles.page_bg_item} ${styles.bg2} page_bg_item`}/>
                <div className={`${styles.page_bg_item} ${styles.bg3} page_bg_item`}/>
                <div className={`${styles.page_bg_item} ${styles.bg4} page_bg_item`}/>
                <div className={`${styles.page_bg_item} ${styles.bg5} page_bg_item`}/>
                {/*<div className={`${styles.page_bg_item} ${styles.bg6} page_bg_item`}/>*/}
                <div className={`${styles.page_bg_item} ${styles.bg7} page_bg_item`}/>
            </div>
            <div className={styles.page_content}>
                <Header/>
                {activeBlock()}
                <div className={styles.page_copy}>© {DATE.CURRENT_YEAR} LoveToCrunch.com All rights reserved &nbsp;<span>
                    <a href="#terms-and-conditions" onClick={() => initTerm.openTermModal('Terms and Conditions')}>Terms and Conditions</a>
                    </span> | <span><a href="#privacy-policy" onClick={() => initTerm.openTermModal('Privacy Policy')}> Privacy Policy</a></span>
                </div>
                {
                    !!cookiesBannerActive &&
                    <div className={styles.page_policy}>
                        <div className={styles.page_policy_text}>By continuing to use our site you <br/> are agreeing to
                            our
                            cookie policy
                        </div>
                        <div className={styles.page_policy_link}>
                            <a href="#cookie-policy" onClick={() => initTerm.openTermModal('Cookie Policy')}> Learn
                                more</a>
                        </div>
                        <div className={styles.page_policy_close} onClick={() => setCookiesBannerActive(false)}>
                            <img src={img.images['cross.svg']} alt=""/>
                        </div>
                    </div>
                }
            </div>
            <TermsModal {...initTerm}/>
            <LoaderModal modalIsActive={modalIsActiveLoader}/>
        </>
    );
}
;
